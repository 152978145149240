import styled from 'styled-components'

import { title7Styles } from '@styles/typography/title'

export const StyledButton = styled.button<{ $isExpanded: boolean }>`
  ${title7Styles};
  position: absolute;
  z-index: 2;
  left: 50%;
  bottom: -24px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  padding: 12px 24px;
  border-radius: ${p => p.theme.borderRadius.sm};
  text-transform: none;
  background-color: ${p => p.theme.colors.darkCharcoal};
  color: ${p => p.theme.colors.white};
  outline: none;

  ${p => p.theme.mediaQueries.mobile} {
    width: calc(100% - 48px);
  }

  svg {
    transform: rotate(${p => (p.$isExpanded ? '180deg' : '0')});
    transition: ${p => p.theme.transitions.default};
    fill: ${p => p.theme.colors.white};
  }
`
