import dynamic from 'next/dynamic'

import { useAppSelector } from '@redux/hooks'
import { selectIsPageInteraction } from '@reduxCommonApp/commonApp.selectors'

import LanguageSelectStub from '@components/LanguageSelect/components/Stub'

const LanguageSelect = dynamic(() => import('./components/Select'))

export const LanguageSelectContainer = () => {
  const isPageInteraction = useAppSelector(selectIsPageInteraction)

  return isPageInteraction ? <LanguageSelect /> : <LanguageSelectStub />
}
