import styled from 'styled-components'

import { textMediumRegularStyles } from '@styles/typography/text'

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  column-gap: 8px;
`

export const Label = styled.span`
  ${textMediumRegularStyles};
  color: ${p => p.theme.colors.white};
`
