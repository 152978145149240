import styled, { css } from 'styled-components'

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: min-content;
  margin-top: 90px;
  background-color: ${p => p.theme.colors.blackModern};
`
export const TopBlock = styled.div`
  padding-top: 48px;
  background-color: ${p => p.theme.colors.black};

  ${p => p.theme.mediaQueries.desktopXS} {
    padding-top: 32px;
  }

  ${p => p.theme.mediaQueries.mobileL} {
    padding-top: 24px;
  }
`

const commonBlock = css`
  max-width: 1096px;
  margin-inline: auto;
  padding-block: 48px;
  padding-inline: 32px;

  ${p => p.theme.mediaQueries.mobile} {
    padding-inline: 24px;
  }
`

export const BottomBlock = styled.div`
  ${commonBlock};
  display: grid;
  grid-template-columns: 1fr minmax(150px, auto);
  grid-template-rows: auto auto;
  gap: 40px;
  grid-template-areas:
    'info lang'
    'copy lang';
  min-height: 200px;

  ${p => p.theme.mediaQueries.desktopXS} {
    margin-inline: inherit;
    gap: 24px;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'info info'
      'lang lang'
      'copy copy';
  }

  ${p => p.theme.mediaQueries.mobile} {
    padding-block: 40px 24px;
  }
`

export const TopBlockWrapper = styled.div`
  ${commonBlock};
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 48px;

  ${p => p.theme.mediaQueries.desktopXS} {
    row-gap: 24px;
  }
`
