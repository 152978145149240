import styled from 'styled-components'

import Button from '@base/buttons/Button'
import { title5Styles } from '@styles/typography/title'

export const Container = styled.div`
  display: flex;
  column-gap: 46px;

  ${p => p.theme.mediaQueries.desktopXS} {
    flex-direction: column;
    row-gap: 24px;
  }
`

export const Title = styled.h5`
  * {
    ${title5Styles};
    line-height: 30px;
    color: ${p => p.theme.colors.white};
  }
`

export const Wrapper = styled.div`
  display: flex;
  column-gap: 24px;
`

export const LinkDownload = styled(Button)`
  &.ant-btn {
    width: 240px;
    height: 64px;
    padding: 0;
    border-radius: ${p => p.theme.borderRadius.sm};

    ${p => p.theme.mediaQueries.desktopXS} {
      width: 100%;
    }

    &,
    &:active,
    &:not(:disabled):not(.ant-btn-disabled):active,
    &:focus,
    &:not(:disabled):not(.ant-btn-disabled):focus,
    &:hover,
    &:not(:disabled):not(.ant-btn-disabled):hover {
      background: ${props => props.theme.colors.whiteOpacity10};
    }

    span {
      color: inherit;
    }

    img {
      width: 156px;
      height: 52px;
    }
  }
`
