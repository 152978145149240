import type { FC } from 'react'

import { LinkStyled, Wrapper } from './styles'

type Props = {
  href: string
  title: string
}

export const PopularRouteLink: FC<Props> = ({ href, title }) => {
  return (
    <Wrapper>
      <LinkStyled href={href} prefetch={false}>
        {title}
      </LinkStyled>
    </Wrapper>
  )
}
