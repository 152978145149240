import { useGetFooterConfigQuery } from '@reduxCommonApp/commonApp.api'

import useLangCode from '@hooks/useLangCode/useLangCode'

import FooterCopyRight from './components/CopyRight'
import FooterInfo from './components/Info'
import LanguageAndTerms from './components/LanguageAndTerms'
import MobileAppBlock from './components/MobileAppBlock'
import FooterPopularRoutes from './components/PopularRoutes'
import { BottomBlock, Container, TopBlock, TopBlockWrapper } from './styles'

//https://www.figma.com/design/RSKNwPqCiRU65pDeqEj3e5/Footer?node-id=0-1&t=hG6sHHK27L2tEMGY-0

export const FooterClassic = () => {
  const { data } = useGetFooterConfigQuery()
  const langCode = useLangCode()

  return (
    <Container>
      <TopBlock>
        <TopBlockWrapper>
          <MobileAppBlock blockId={data?.app_links_block} />
          <FooterPopularRoutes menuId={data?.menu_reference[langCode]} />
        </TopBlockWrapper>
      </TopBlock>
      <BottomBlock>
        <FooterInfo blocks={data?.blocks} />
        <FooterCopyRight
          allRightsReserved={data?.all_rights_reserved}
          disclaimer={data?.disclaimer}
          registeredTrademark={data?.registered_trademark}
        />
        <LanguageAndTerms links={data?.page_links} />
      </BottomBlock>
    </Container>
  )
}
