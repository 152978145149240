import useLangCode from '@hooks/useLangCode/useLangCode'
import useLanguageList from '@hooks/useLanguageList/useLanguageList'
import AngleDownIcon from '@iconscout/unicons/svg/line/angle-down.svg'

import OptionItem from '../Option'
import { Container } from './styles'

export const LanguageSelectStub = () => {
  const langList = useLanguageList()
  const currentLangCode = useLangCode()

  return (
    <Container>
      <OptionItem code={currentLangCode} label={langList[currentLangCode].name} />
      <AngleDownIcon />
    </Container>
  )
}
