import styled from 'styled-components'

export const Container = styled.div`
  grid-area: info;
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;

  ${p => p.theme.mediaQueries.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    row-gap: 24px;
  }
`
