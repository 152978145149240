import styled from 'styled-components'

import { textXSmallRegularStyles } from '@styles/typography/text'

export const Container = styled.div`
  grid-area: copy;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 'copy text text';
  column-gap: 24px;

  ${p => p.theme.mediaQueries.mobile} {
    row-gap: 16px;
    grid-template-columns: repeat(2, auto);
    grid-template-areas:
      'text text'
      'copy copy';
  }
`
export const Wrapper = styled.div`
  grid-area: copy;
  display: flex;
  justify-content: space-between;
`

export const TextWrapper = styled.div`
  grid-area: text;
  display: inline-flex;
`

export const Text50 = styled.span`
  ${textXSmallRegularStyles};
  color: ${p => p.theme.colors.whiteOpacity50};
`

export const Text25 = styled.span`
  ${textXSmallRegularStyles};
  color: ${p => p.theme.colors.whiteOpacity25};
`
