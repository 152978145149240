import styled from 'styled-components'

import { textMediumMediumStyles, textXSmallRegularStyles } from '@styles/typography/text'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`
export const Caption = styled.span`
  ${textMediumMediumStyles};
  color: ${p => p.theme.colors.white};
`

export const InfoText = styled.span`
  ${textXSmallRegularStyles};
  color: ${p => p.theme.colors.whiteOpacity50};
`
