import type { FC } from 'react'

import type { IFooterInfoBlock } from '@Types/footer/footer'

import InfoItem from './InfoItem'
import { Container } from './styles'

type Props = {
  blocks?: IFooterInfoBlock[]
}

export const FooterInfo: FC<Props> = ({ blocks }) => {
  return (
    <Container>
      {blocks?.map((el, index) => {
        return <InfoItem item={el} key={index} />
      })}
    </Container>
  )
}
