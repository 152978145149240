import type { FC } from 'react'

import { Container, Text25, Text50, TextWrapper, Wrapper } from './styles'

type Props = {
  allRightsReserved?: string
  disclaimer?: string
  registeredTrademark?: string
}

export const FooterCopyRight: FC<Props> = ({ allRightsReserved, disclaimer, registeredTrademark }) => {
  return (
    <Container>
      <Wrapper>
        <Text50>{registeredTrademark}</Text50>
        <Text25>{allRightsReserved}</Text25>
      </Wrapper>
      <TextWrapper>
        <Text25>{disclaimer}</Text25>
      </TextWrapper>
    </Container>
  )
}
