import dynamic from 'next/dynamic'
import type { FC } from 'react'

import { useGetBlockQuery } from '@redux/features/homePage/homePage.api'
import { useAppSelector } from '@redux/hooks'
import { selectIsMobileUserAgent } from '@reduxCommonApp/commonApp.selectors'
import { skipToken } from '@reduxjs/toolkit/query/react'

import type { MobileAppsLinksType } from '@Types/blocks/mobileAppsLinksType'

import { Container, LinkDownload, Title, Wrapper } from './styles'

const QrCode = dynamic(() => import('@components/QrCode'))

type Props = {
  blockId?: number
}

export const MobileAppBlock: FC<Props> = ({ blockId }) => {
  const { data } = useGetBlockQuery(blockId ?? skipToken)
  const mobileAppsLinks = data as MobileAppsLinksType
  const isMobileUserAgent = useAppSelector(selectIsMobileUserAgent)

  if (!mobileAppsLinks) return

  return (
    <Container>
      <Title dangerouslySetInnerHTML={{ __html: mobileAppsLinks.body.value }} />
      <Wrapper>
        {!isMobileUserAgent && <QrCode url={mobileAppsLinks.qr_code.uri.url} />}

        <LinkDownload href={mobileAppsLinks.app_store_link.uri} target="_blank">
          <img alt="app store icon" src={mobileAppsLinks.app_store_image.uri.url} />
        </LinkDownload>

        <LinkDownload href={mobileAppsLinks.google_store_link.uri} target="_blank">
          <img alt="google store icon" src={mobileAppsLinks.svg_icon.uri.url} />
        </LinkDownload>
      </Wrapper>
    </Container>
  )
}
