import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  min-width: 150px;
  padding-inline: 24px 14px;
  border-radius: ${p => p.theme.borderRadius.sm};
  border: 1px solid ${p => p.theme.colors.whiteOpacity10};

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    inset-inline-start: auto;
    inset-inline-end: 11px;
    margin-inline-start: 9px;
    fill: ${p => p.theme.colors.white};
  }
`
