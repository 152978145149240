import type { FC } from 'react'

import type { ConfigurationFooterType } from '@Types/common/configurationBlocks'

import { Caption, Container, InfoText } from './styles'

type Props = {
  item: ConfigurationFooterType
}

export const InfoItem: FC<Props> = ({ item }) => {
  return (
    <Container>
      <Caption>{item.title}</Caption>
      <InfoText>{item.description}</InfoText>
    </Container>
  )
}
