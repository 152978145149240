import Link from 'next/link'
import styled from 'styled-components'

import { textSmallRegularStyles } from '@styles/typography/text'
import { title7Styles } from '@styles/typography/title'

export const Container = styled.div`
  grid-area: lang;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  ${p => p.theme.mediaQueries.desktopXS} {
    flex-direction: row-reverse;
    column-gap: 48px;
  }

  ${p => p.theme.mediaQueries.mobile} {
    flex-direction: column;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  ${p => p.theme.mediaQueries.desktopXS} {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  ${p => p.theme.mediaQueries.mobile} {
    row-gap: 24px;
  }

  .ant-btn {
    justify-content: flex-end;

    ${p => p.theme.mediaQueries.desktopXS} {
      justify-content: center;
    }

    span {
      ${textSmallRegularStyles};
      color: ${p => p.theme.colors.whiteOpacity50};
      text-transform: none;
      text-align: end;

      &:hover {
        color: ${p => p.theme.colors.white};
      }
    }
  }
`

export const StyledLink = styled(Link)`
  ${textSmallRegularStyles};
  color: ${p => p.theme.colors.whiteOpacity50};
  text-align: end;
  font-weight: 700;
`

export const SupportLink = styled(StyledLink)`
  ${p => p.theme.mediaQueries.mobile} {
    ${title7Styles};
    letter-spacing: 0;
    width: 100%;
    text-align: center;
    padding: 12px 24px;
    border-radius: ${p => p.theme.borderRadius.sm};
    text-transform: none;
    background-color: ${p => p.theme.colors.darkCharcoal};
    color: ${p => p.theme.colors.white};
    border-radius: ${p => p.theme.borderRadius.sm};
  }

  &:hover {
    color: ${p => p.theme.colors.white};
  }
`
