import type { FC } from 'react'

import { useGetMenuItemsQuery } from '@redux/features/header/api'
import { skipToken } from '@reduxjs/toolkit/query/react'

import useToggle from '@hooks/useToggle/useToggle'

import PopularRouteLink from './Route'
import ShowMoreButton from './ShowMoreButton'
import { List } from './styles'

type Props = {
  menuId?: string
}

export const FooterPopularRoutes: FC<Props> = ({ menuId }) => {
  const { data: popularRoutes } = useGetMenuItemsQuery(menuId ?? skipToken)
  const { on: isExpanded, toggle: toggleIsExpanded } = useToggle()

  if (!popularRoutes) return

  const popularRoutesLength = popularRoutes.length

  return (
    <>
      <List $isExpanded={isExpanded}>
        {popularRoutes.map(item => {
          return <PopularRouteLink href={item.relative} key={item.key} title={item.title} />
        })}
      </List>
      {popularRoutesLength > 16 && <ShowMoreButton isExpanded={isExpanded} onClick={toggleIsExpanded} />}
    </>
  )
}
