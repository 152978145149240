import styled from 'styled-components'

export const List = styled.ul<{ $isExpanded: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px 24px;
  max-height: ${p => (p.$isExpanded ? 'auto' : '128px')};
  padding: 0;
  list-style-type: none;
  transition: ${p => p.theme.transitions.default};
  overflow: hidden;

  ${p => p.theme.mediaQueries.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${p => p.theme.mediaQueries.mobile} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${p => p.theme.mediaQueries.mobileM} {
    grid-template-columns: 1fr;
  }

  &:before {
    display: ${p => (p.$isExpanded ? 'none' : 'block')};
    position: absolute;
    content: '';
    height: 112px;
    left: 0;
    bottom: -48px;
    background: linear-gradient(180deg, transparent 0%, black 100%);
    width: 100%;
    z-index: 3;
    pointer-events: none;
  }
`
