import type { FC } from 'react'

import FlagIcon from '@base/FlagIcon'

import { Label, Wrapper } from './styles'

type Props = {
  code: string
  label: string
}

export const OptionItem: FC<Props> = ({ code, label }) => {
  return (
    <Wrapper>
      {code && <FlagIcon code={code === 'en' ? 'us' : code} width="24px" />}
      <Label>{label}</Label>
    </Wrapper>
  )
}
