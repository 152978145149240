import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import AngleDownIcon from '@iconscout/unicons/svg/line/arrow-down.svg'

import { StyledButton } from './styles'

type Props = {
  isExpanded: boolean
  onClick?: () => void
}

export const ShowMoreButton: FC<Props> = ({ isExpanded, onClick }) => {
  const { t } = useTranslation('Popular routes')
  return (
    <StyledButton $isExpanded={isExpanded} onClick={onClick}>
      {t('ShowMorePopularRoutes')} <AngleDownIcon />
    </StyledButton>
  )
}
