import Link from 'next/link'
import styled from 'styled-components'

import { textSmallRegularStyles } from '@styles/typography/text'

export const Wrapper = styled.li`
  display: inline-flex;

  ${p => p.theme.mediaQueries.mobileM} {
    justify-content: center;
  }
`

export const LinkStyled = styled(Link)`
  ${textSmallRegularStyles};
  line-height: 20px;
  white-space: break-spaces;
  color: ${p => p.theme.colors.whiteOpacity50};

  &:hover {
    color: ${p => p.theme.colors.white};
  }
`
