import type { FC } from 'react'

import type { IFooterLinksBlock } from '@Types/footer/footer'
import LanguageSelectContainer from '@components/LanguageSelect'

import { Container, StyledLink, SupportLink, Wrapper } from './styles'

type Props = {
  links?: IFooterLinksBlock[]
}

export const LanguageAndTerms: FC<Props> = ({ links }) => {
  if (!links) return

  return (
    <Container>
      <LanguageSelectContainer />
      <Wrapper>
        {links.map(item => {
          if (item.id === 'customer_support') {
            return (
              <SupportLink href={item.uri} key={item.id}>
                {item.title}
              </SupportLink>
            )
          }
          return (
            <StyledLink href={item.uri} key={item.id}>
              {item.title}
            </StyledLink>
          )
        })}
      </Wrapper>
    </Container>
  )
}
